import { n as normalizeComponent, B as BarcodeScanner, M as MyMixin } from "./index.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-modal", {
          attrs: {
            "after-close": _vm.afterModalClose,
            "title": "Add New Asset"
          },
          scopedSlots: _vm._u([{
            key: "footer",
            fn: function() {
              return [_c("a-button", {
                on: {
                  "click": _vm.cancel
                }
              }, [_vm._v("Cancel")]), _c("a-button", {
                attrs: {
                  "loading": _vm.isLoading,
                  "type": "primary",
                  "disabled": _vm.disableRegisterButton || _vm.isLoading
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }, [_vm._v(" Register ")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v;
            },
            expression: "visible"
          }
        }, [_c("a-row", {
          attrs: {
            "gutter": 16
          }
        }, [_c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("div", {
          staticClass: "d-flex align-items-center w-100"
        }, [_c("text-input", {
          staticClass: "w-100",
          attrs: {
            "label": "Barcode",
            "form-item": "",
            "rules": "required",
            "value": _vm.barcodeValue
          },
          on: {
            "change": function($event) {
              return _vm.storeValue(`barcode`, $event);
            }
          }
        }), _c("a-button", {
          staticClass: "mb-1 ml-2 px-2",
          attrs: {
            "icon": "camera"
          },
          on: {
            "click": _vm.onScanBarcode
          }
        })], 1), _c("barcode-scanner", {
          attrs: {
            "visible": _vm.activeBarcodeScanner
          },
          on: {
            "update:visible": function($event) {
              _vm.activeBarcodeScanner = $event;
            },
            "change": _vm.onScanComplete
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Location",
            "rules": "required",
            "form-item": ""
          },
          on: {
            "change": function($event) {
              return _vm.storeValue(`location`, $event);
            },
            "focus": _vm.validateBarCode
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 12
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Longitude",
            "rules": "required",
            "form-item": "",
            "disabled": true,
            "value": _vm.longitude
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 12
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Latitude",
            "rules": "required",
            "form-item": "",
            "disabled": true,
            "value": _vm.latitude
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Description",
            "disabled": true,
            "form-item": "",
            "value": _vm.assetInfo ? _vm.assetInfo.description ? _vm.assetInfo.description : "No details in BPCS found" : ""
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Last Count Location",
            "disabled": true,
            "form-item": "",
            "value": _vm.assetInfo ? _vm.assetInfo.lastCountLocation ? _vm.assetInfo.lastCountLocation : "No details in BPCS found" : ""
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Installation Date",
            "disabled": true,
            "form-item": "",
            "value": _vm.assetInfo ? _vm.assetInfo.installationDate ? _vm.$moment(_vm.assetInfo.installationDate).format("DD/MM/YYYY") : "No details in BPCS found" : ""
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Original Cost",
            "disabled": true,
            "form-item": "",
            "value": _vm.assetInfo ? _vm.assetInfo.originalCost ? _vm.assetInfo.originalCost : "No details in BPCS found" : ""
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Net Book Value",
            "disabled": true,
            "form-item": "",
            "value": _vm.assetInfo ? _vm.assetInfo.netBookValue ? _vm.assetInfo.netBookValue : "No details in BPCS found" : ""
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("span", {
          staticClass: "neutral-10--text"
        }, [_vm._v("Sitemap")]), _vm.listSiteMap.length > 0 ? _c("upload-input", {
          attrs: {
            "form-item": "",
            "type": "path",
            "upload-type": "multiFile",
            "action": _vm.actionUrl,
            "file-list": _vm.listSiteMap,
            "label": "Image",
            "max-file-size": 10,
            "max-list-length": _vm.listSiteMap.length,
            "disabled": true
          }
        }) : _c("div", {
          staticClass: "mb-4"
        }, [_vm._v("No Site Map")])], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-area", {
          attrs: {
            "label": "Comments",
            "rows": 5,
            "form-item": ""
          },
          on: {
            "change": function($event) {
              return _vm.storeValue(`comment`, $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("span", [_vm._v(" Attachments (Maximum 2 pics) ")]), _c("upload-input", {
          attrs: {
            "form-item": "",
            "upload-type": "multiFile",
            "action": _vm.actionUrl,
            "file-list": _vm.fileList,
            "label": "Attachments",
            "rules": "required",
            "max-list-length": 2,
            "confirm-remove-content": "Are you sure that you want to delete the selected attachment?",
            "max-file-size": 10
          },
          on: {
            "change": _vm.onUpload
          }
        })], 1)], 1)], 1), _c("a-modal", {
          attrs: {
            "title": "Duplicate Barcodes",
            "width": 530,
            "ok-text": "Yes",
            "cancel-text": "No"
          },
          on: {
            "cancel": _vm.onDuplicateModalCancel,
            "ok": function($event) {
              return _vm.pushToRouteWithQuery(`/assets/${_vm.barcodeDuplicateId}`, {
                barcode: _vm.barcodeValue
              });
            }
          },
          model: {
            value: _vm.duplicateModalVisible,
            callback: function($$v) {
              _vm.duplicateModalVisible = $$v;
            },
            expression: "duplicateModalVisible"
          }
        }, [_vm._v(" This asset number has already been counted this year. Do you want to edit the Asset Details? ")])];
      }
    }])
  });
};
var staticRenderFns$1 = [];
const POSITION_ERROR = {
  permissionDenied: 1,
  positionUnavailable: 2
};
const __vue2_script$1 = {
  name: "CreateAsset",
  components: { BarcodeScanner },
  inject: ["crud", "apiUrl"],
  mixins: [MyMixin],
  data() {
    return {
      BarcodeScanner,
      visible: false,
      duplicateModalVisible: false,
      createForm: {},
      isLoading: false,
      barcodeValue: "",
      detecteds: [],
      activeBarcodeScanner: false,
      actionUrl: this.apiUrl + "/files/images",
      previewVisible: false,
      previewImage: "",
      fileList: [],
      facilityInfo: {},
      listSiteMap: [],
      disableRegisterButton: true,
      barcodeDuplicateId: "",
      assetInfo: void 0,
      isExistedBarcode: true,
      longitude: "",
      latitude: ""
    };
  },
  created() {
    const { id, status } = this.$route.query;
    this.storeValue("assetRegisterId", id);
    this.storeValue("status", status);
    this.storeValue("comment", "");
    navigator.geolocation.getCurrentPosition((position) => {
      this.longitude = position.coords.longitude;
      this.latitude = position.coords.latitude;
      this.storeValue("longitude", position.coords.longitude);
      this.storeValue("latitude", position.coords.latitude);
    }, (err) => {
      switch (err.code) {
        case POSITION_ERROR.permissionDenied:
          this.$notification["error"]({
            message: "Location access denied!"
          });
          return;
        case POSITION_ERROR.positionUnavailable:
          this.$notification["error"]({
            message: "Location is unavailable!"
          });
          return;
      }
    });
  },
  async mounted() {
    this.visible = true;
    await this.getFacilityInfo();
  },
  methods: {
    afterModalClose() {
      this.pushToRouteWithQuery("/assets");
    },
    async getFacilityInfo() {
      const { data } = await this.axios.get(`${this.apiUrl}/fas/assetregisters/${this.$route.query.id}`);
      this.facilityInfo = data;
      this.listSiteMap = data.siteMapUrl.map((item) => {
        return {
          ...item,
          name: item.path,
          url: this.apiUrl + item.path,
          response: { path: item.path }
        };
      });
    },
    async checkDuplicateBarcode(barcode) {
      if (!barcode)
        return;
      const barcodeFilter = `(Barcode Eq ${"`" + encodeURIComponent(barcode) + "`"})`;
      const assetIdFilter = `(AssetRegisterId Eq ${"`" + encodeURIComponent(this.$route.query.id) + "`"})`;
      const filter = `${barcodeFilter}and${assetIdFilter}`;
      const { data } = await this.axios.get(`${this.apiUrl}/fas/assets?fe=(${filter})`);
      if (data.results.length > 0) {
        this.duplicateModalVisible = true;
        this.barcodeDuplicateId = data.results[0].id;
        return true;
      } else {
        return false;
      }
    },
    async checkBarcodeExisted(barcode) {
      if (!barcode) {
        this.assetInfo = void 0;
        return;
      }
      await this.axios.get(`${this.apiUrl}/fas/barcoderegisters/barcode`, {
        params: {
          barcode,
          year: this.facilityInfo.year,
          countryCode: this.facilityInfo.countryCode
        }
      }).then((res) => {
        this.assetInfo = res.data;
        this.disableRegisterButton = false;
        return true;
      }).catch((err) => {
        this.$error({
          title: "Invalid Barcode",
          content: "This barcode does not exist in the system. Please contact Administrator."
        });
        this.assetInfo = void 0;
        this.disableRegisterButton = true;
        return false;
      });
    },
    onScanBarcode() {
      this.activeBarcodeScanner = true;
    },
    async onScanComplete(value) {
      this.barcodeValue = value;
      this.storeValue("barcode", value);
    },
    async validateBarCode(value) {
      if (value && this.createForm.barcode) {
        const isDup = await this.checkDuplicateBarcode(this.createForm.barcode);
        this.barcodeValue = this.createForm.barcode;
        if (!isDup) {
          await this.checkBarcodeExisted(this.createForm.barcode);
        }
      }
    },
    onDuplicateModalCancel() {
      this.createForm.barcode = null;
      this.barcodeValue = null;
      this.duplicateModalVisible = false;
    },
    onExistedModalCancel() {
      this.barcodeValue = null;
    },
    onUpload(res) {
      this.storeValue("attachments", res);
    },
    storeValue(resourceKey, value) {
      const createForm = this.createForm;
      this.createForm = {
        ...createForm,
        [resourceKey]: value
      };
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    async submit() {
      this.isLoading = true;
      await this.crud.submitEntity("create").then(() => {
        this.createForm = {};
        this.isLoading = false;
        this.crud.fetchList();
        this.visible = false;
      }).catch(() => this.isLoading = false);
    },
    cancel() {
      this.visible = false;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateAsset = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", {
    attrs: {
      "name": "fas.assets",
      "api-url": _vm.apiUrl,
      "redirect-route": "/assets"
    }
  }, [_c("create-asset")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { CreateAsset },
  data() {
    return {
      CreateAsset,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
